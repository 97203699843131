<template>
  <v-container class="general">
    <!-- Steps -->
    <div class="d-flex justify-space-between row stepper-header">
      <div
        v-for="(step, i) in steps"
        :key="`step-${i}`"
        :class="[{ complete: i < stepper, active: i === stepper }]"
        class="pa-3 text-center step"
      >
        <div class="step-content">
          <v-avatar size="40" class="mb-1 theme-accent-bg">
            <span class="white--text font-weight-medium">{{ i + 1 }}</span>
          </v-avatar>
          <div class="text-center font-weight-medium theme-accent caption">
            {{ step.title }}
          </div>
        </div>
      </div>
    </div>
    <v-window v-model="stepper" class="mb-5">
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
      >
        <component
          :is="step.component"
          @administrator-created="listGroupAdministrators"
          @administrator-deleted="listGroupAdministrators"
        ></component>
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <div v-if="stepper < steps.length - 1">
      <v-btn round class="ml-0 theme-accent text-none" @click="closeWizard">{{
        $t("common.cancel")
      }}</v-btn>
      <v-btn
        round
        class="ml-0 theme-accent-bg white--text text-none"
        :loading="isLoading"
        @click="changeNext"
        >{{ $t("common.continue") }}</v-btn
      >
    </div>
    <div class="text-center" v-if="stepper === steps.length - 1">
      <v-btn
        round
        class="theme-accent-bg white--text text-none"
        @click="goToUserList"
        >{{ $t("course_wizard.finish") }}</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import OverallSettings from "@/components/CourseWizard/OverallSettings.vue";
import Evaluations from "@/components/CourseWizard/Evaluations.vue";
import Teachers from "@/components/CourseWizard/Teachers.vue";
import Coordinators from "@/components/CourseWizard/Coordinators.vue";
import Registration from "@/components/CourseWizard/Registration.vue";
import Description from "@/components/CourseWizard/Description.vue";
import Overview from "@/components/CourseWizard/Overview.vue";
import moment from "moment-timezone";
import { features } from "@/components/CourseWizard/Configs/features.json";
import { audiences } from "@/components/CourseWizard/Configs/audiences.json";
import { messageTemplates } from "@/components/CourseWizard/Configs/messageTemplates.json";
import { triggers } from "@/components/CourseWizard/Configs/triggers.json";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    stepper: 0,
    dateFormat: "YYYY-MM-DDTHH:mm:ssZ",
    registrationBaseUrl: "",
    groupPlugin: {
      audiences: [],
      messageTemplates: [],
      triggers: [],
    },
    groupReport: {},
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    printoutTemplates() {
      return this.$store.getters.courseWizardPrintoutTemplates;
    },
    evaluationTemplates() {
      return this.$store.getters.courseWizardEvaluationTemplates;
    },
    groupEvaluations() {
      return this.$store.getters.courseWizardGroupEvaluations;
    },
    groupRegistrations() {
      return this.$store.getters.courseWizardGroupRegistrations;
    },
    administrators() {
      return this.$store.getters.courseWizardAdministrators;
    },
    teachers() {
      return this.administrators.filter((el) =>
        el.group_administration_roles.includes("teacher"),
      );
    },
    coordinators() {
      return this.administrators.filter((el) =>
        el.group_administration_roles.includes("coordinator"),
      );
    },
    group: {
      get() {
        return this.$store.getters.courseWizardGroup;
      },
      set(v) {
        this.$store.dispatch("setCourseWizardGroup", v);
      },
    },
    localStorageAudiences() {
      return (
        this.$store.getters.groupAudienceDataStorage.filter(
          (el) => el.group_id === this.group.id,
        ) || []
      );
    },
    localStorageMessageTemplates() {
      return (
        this.$store.getters.groupMessageTemplateDataStorage.filter(
          (el) => el.group_id === this.group.id,
        ) || []
      );
    },
    localStorageTriggers() {
      return (
        this.$store.getters.groupTriggerDataStorage.filter(
          (el) => el.group_id === this.group.id,
        ) || []
      );
    },
    steps() {
      return [
        {
          name: "settings",
          title: this.$t("course_wizard.overall_settings"),
          component: OverallSettings,
          enabled: true,
        },
        {
          name: "evaluations",
          title: this.$t("course_wizard.evaluations"),
          component: Evaluations,
          enabled: true,
        },
        {
          name: "teachers",
          title: this.$t("course_wizard.teachers"),
          component: Teachers,
          enabled: true,
        },
        {
          name: "coordinators",
          title: this.$t("course_wizard.coordinators"),
          component: Coordinators,
          enabled: this.group.public === 0 ? true : false,
        },
        {
          name: "registration",
          title: this.$t("course_wizard.registration"),
          component: Registration,
          enabled: true,
        },
        {
          name: "description",
          title: this.$t("course_wizard.description"),
          component: Description,
          enabled: true,
        },
        {
          name: "overview",
          title: this.$t("course_wizard.overview"),
          component: Overview,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    activeStep() {
      return this.steps[this.stepper].name;
    },
  },
  beforeCreate() {
    this.$store.dispatch("setCourseWizardGroup", {
      course_description: "",
      course_notes: "",
      course_signature: "",
      public: 1,
    });
    this.$store.dispatch("setCourseWizardTags", []);
    this.$store.dispatch("setCourseWizardVenues", []);
    this.$store.dispatch("setCourseWizardEvaluationTemplates", []);
    this.$store.dispatch("setCourseWizardGroupEvaluations", []);
    this.$store.dispatch("setCourseWizardRegistrationTemplates", []);
    this.$store.dispatch("setCourseWizardGroupRegistrations", []);
    this.$store.dispatch("setCourseWizardAdministrators", []);

    this.groupReport = {};
  },
  mounted() {
    this.listPrintoutTemplates();
    this.listTags();
    this.listVenues();
    this.listTemplates();
    this.getDefaultSignature();
    this.getRegistrationBaseUrl();
  },
  methods: {
    async getRegistrationBaseUrl() {
      try {
        const response = await this.$api.settings.get("registration.base_url");

        this.registrationBaseUrl = response.data.data.value;
      } catch (error) {}
    },
    async getDefaultSignature() {
      if (this.group.id) return;

      try {
        const response = await this.$api.settings.get("global.signature");

        if (this.group.course_signature) return;

        this.group.course_signature = response.data.data.value;
      } catch (error) {}
    },
    setPlaceholders(string) {
      if (!string) return;

      const placeholders = [
        {
          key: "{{location_origin}}",
          value: location.origin,
        },
        {
          key: "{{registration_base_url}}",
          value: this.registrationBaseUrl,
        },
        {
          key: "{{group_id}}",
          value: this.group.id,
        },
        {
          key: "{{event_signup_min}}",
          value: this.group.group_min_subscribtions,
        },
        {
          key: "{{event_evaluation1}}",
          value: this.group.course_evaluation1,
        },
        {
          key: "{{event_evaluation2}}",
          value: this.group.course_evaluation2,
        },
        {
          key: "{{event_signature}}",
          value: this.group.course_signature,
        },
        {
          key: "{{event_notes}}",
          value: this.group.course_notes_email ? this.group.course_notes : "",
        },
        {
          key: "{{report_id}}",
          value: this.groupReport.id,
        },
      ];

      const keys = placeholders.map((el) => el.key).join("|");
      const regExp = new RegExp(keys, "g");

      const getPlaceholderValue = (key) => {
        const placeholder = placeholders.find((el) => el.key === key);
        return placeholder.value;
      };

      string = string.replace(regExp, function(matched) {
        return getPlaceholderValue(matched);
      });

      return string;
    },
    transformGroupPluginForEdit(model) {
      if (!model) {
        return {
          audiences: [],
          messageTemplates: [],
          triggers: [],
        };
      }

      const audiences = model.attributes.audience_data_storage;
      const messageTemplates = model.attributes.message_template_data_storage;
      const triggers = model.attributes.trigger_data_storage;

      return {
        id: model.id,
        audiences: JSON.parse(audiences) || [],
        messageTemplates: JSON.parse(messageTemplates) || [],
        triggers: JSON.parse(triggers) || [],
      };
    },
    async getGroupPlugin() {
      const pluginsList = {
        params: [
          this.groupId,
          {
            prefix: "coursewizard",
            with_attributes: 1,
            page: 1,
            per_page: 1,
          },
        ],
      };

      pluginsList.response = await this.$api.groupPlugins.list(
        ...pluginsList.params,
      );

      const plugin = pluginsList.response.data.data[0];

      if (!plugin) return;

      this.groupPlugin = this.transformGroupPluginForEdit(plugin);
    },
    async fetchGroupData() {
      if (!this.groupId) return;

      this.getGroup();
      this.getGroupPlugin();
      this.listGroupEvaluations();
      this.listGroupRegistrations();
      this.listGroupAdministrators();
      this.getGroupReport();
    },
    async createGroupPlugin(groupId) {
      if (!groupId) return;

      const specs = [
        groupId,
        {
          name: "Course wizard messages and triggers",
          prefix: "coursewizard",
          with_attributes: 1,
        },
      ];

      const response = await this.$api.groupPlugins.create(...specs);

      const plugin = response.data.data;

      if (!plugin) return;

      this.groupPlugin = this.transformGroupPluginForEdit(plugin);
    },
    listTags() {
      const params = {
        per_page: 100,
      };

      this.$api.groupTags.list(params).then(
        (response) => {
          if (!response.data || !response.data.data) {
            return;
          }
          this.$store.dispatch(
            "setCourseWizardTags",
            response.data.data.map((el) => el.name),
          );
        },
        () => {},
      );
    },
    listVenues() {
      const params = {
        sortby: 'name',
        sortdir: 'asc',
        per_page: 200,
      };

      this.$api.groupVenues.list(params).then(
        (response) => {
          if (!response.data || !response.data.data) {
            return;
          }
          this.$store.dispatch("setCourseWizardVenues", response.data.data);
        },
        () => {},
      );
    },
    listPrintoutTemplates() {
      const params = { take: 100, skip: 0 };

      this.$api.printoutTemplates.list(params).then(
        (response) => {
          if (!response) return;

          this.$store.dispatch(
            "setCourseWizardPrintoutTemplates",
            response.data,
          );
        },
        () => {},
      );
    },
    async listEvaluationTemplates(groupId) {
      if (!groupId) return;

      const params = [
        groupId,
        {
          prefix: "questionwizard",
          with_attributes: 1,
          per_page: 100,
        },
      ];

      const response = await this.$api.groupPlugins.list(...params);

      if (!response.data || !response.data.data) {
        return;
      }

      this.$store.dispatch(
        "setCourseWizardEvaluationTemplates",
        response.data.data,
      );
    },
    async listRegistrationTemplates(groupId) {
      if (!groupId) return;

      const params = [
        groupId,
        {
          prefix: "signup",
          per_page: 100,
          with_attributes: 1,
        },
      ];

      const response = await this.$api.groupPlugins.list(...params);

      if (!response.data || !response.data.data) {
        return;
      }

      this.$store.dispatch(
        "setCourseWizardRegistrationTemplates",
        response.data.data,
      );
    },
    async listTemplates() {
      try {
        const params = {
          is_template: 1,
          managed: 1,
          per_page: 1,
        };

        const response = await this.$api.groups.list(params);

        if (!response.data || !response.data.data || !response.data.data[0]) {
          return;
        }

        const group = response.data.data[0];

        await this.listEvaluationTemplates(group.id);
        await this.listRegistrationTemplates(group.id);
      } catch (error) {}
    },
    async listGroupEvaluations() {
      if (!this.groupId) return;

      const params = [
        this.groupId,
        {
          prefix: "questionwizard",
          with_attributes: 1,
          per_page: 100,
        },
      ];

      const response = await this.$api.groupPlugins.list(...params);

      if (!response.data || !response.data.data) {
        return;
      }

      await this.$store.dispatch(
        "setCourseWizardGroupEvaluations",
        response.data.data,
      );
    },
    async listGroupRegistrations() {
      if (!this.groupId) return;

      const params = [
        this.groupId,
        {
          prefix: "signup",
          per_page: 100,
          with_attributes: 1,
        },
      ];

      const response = await this.$api.groupPlugins.list(...params);

      if (!response.data || !response.data.data) {
        return;
      }

      await this.$store.dispatch(
        "setCourseWizardGroupRegistrations",
        response.data.data,
      );
    },
    listGroupAdministrators() {
      if (!this.groupId) return;

      this.errors.clear();

      const params = [
        this.groupId,
        {
          per_page: 100,
        },
      ];

      this.$api.groupAdministrators.list(...params).then(
        (response) => {
          this.$store.dispatch(
            "setCourseWizardAdministrators",
            response.data.data,
          );
        },
        () => {},
      );
    },
    async createGroupFeatures(groupId) {
      if (!groupId) return;

      for (const menu of features) {
        const groupMenusListResponse = await this.$api.groupMenus.list(groupId);

        if (!groupMenusListResponse.data || !groupMenusListResponse.data.data) {
          return;
        }

        let groupMenu = groupMenusListResponse.data.data.find(
          (el) => el.slug === menu.slug,
        );

        if (!groupMenu || !groupMenu.id) {
          const groupMenuCreateSpecs = [
            groupId,
            {
              name: menu.name,
              slug: menu.slug,
              enabled: 1,
            },
          ];

          const groupMenuCreateResponse = await this.$api.groupMenus.create(
            ...groupMenuCreateSpecs,
          );

          if (
            !groupMenuCreateResponse.data ||
            !groupMenuCreateResponse.data.data
          ) {
            return;
          }

          groupMenu = groupMenuCreateResponse.data.data;
        }

        for await (const destination of menu.destinations) {
          let destinationGroupPlugin = {};
          let destinationParams = "";

          if (destination.group_plugin) {
            const groupPluginsListSpecs = [
              groupId,
              {
                prefix: destination.group_plugin.prefix,
                with_attributes: 1,
              },
            ];

            const groupPluginsListResponse = await this.$api.groupPlugins.list(
              ...groupPluginsListSpecs,
            );

            if (
              !groupPluginsListResponse.data ||
              !groupPluginsListResponse.data.data
            ) {
              return;
            }

            destinationGroupPlugin = groupPluginsListResponse.data.data.find(
              (el) => el.attributes.uid === destination.group_plugin.uid,
            );

            if (!destinationGroupPlugin || !destinationGroupPlugin.id) {
              const groupPluginsCreateSpecs = [
                groupId,
                {
                  uid: destination.group_plugin.uid,
                  prefix: destination.group_plugin.prefix,
                  name: destination.group_plugin.uid, // find better solution
                },
              ];

              const groupPluginsCreateResponse = await this.$api.groupPlugins.create(
                ...groupPluginsCreateSpecs,
              );

              if (
                !groupPluginsCreateResponse.data ||
                !groupPluginsCreateResponse.data.data
              ) {
                return;
              }

              destinationGroupPlugin = groupPluginsCreateResponse.data.data;
            }
          }

          if (destination.params) {
            if (destination.component === "AudienceListPage") {
              const groupAudiencesCreateSpecs = [
                groupId,
                destination.params.group_audience,
              ];

              const groupAudiencesCreateResponse = await this.$api.groupAudiences.create(
                ...groupAudiencesCreateSpecs,
              );

              if (
                !groupAudiencesCreateResponse.data ||
                !groupAudiencesCreateResponse.data.data
              ) {
                return;
              }
            }

            if (destination.component === "ArticlePage") {
              const groupArticlesCreateSpecs = [
                groupId,
                {
                  name: destination.params.group_article.name,
                  body: destination.params.group_article.body,
                  group_plugin_id: destinationGroupPlugin.id,
                },
              ];

              const groupArticlesCreateResponse = await this.$api.groupArticles.create(
                ...groupArticlesCreateSpecs,
              );

              if (
                !groupArticlesCreateResponse.data ||
                !groupArticlesCreateResponse.data.data
              ) {
                return;
              }

              destinationParams = JSON.stringify({
                article_id: groupArticlesCreateResponse.data.data.id,
              });
            }
          }

          const groupMenuDestinationsCreateSpecs = [
            groupId,
            groupMenu.id,
            {
              name: this.$t(destination.name),
              icon: destination.icon,
              component: destination.component,
              params: destinationParams || null,
              sort_order: destination.sort_order,
              group_plugin_id: destinationGroupPlugin.id || null,
              is_public: false,
              enabled: 1,
            },
          ];

          const groupMenuDestinationsCreateResponse = await this.$api.groupMenuDestinations.create(
            ...groupMenuDestinationsCreateSpecs,
          );

          if (
            !groupMenuDestinationsCreateResponse.data ||
            !groupMenuDestinationsCreateResponse.data.data
          ) {
            return;
          }
        }
      }
    },
    async duplicateEvaluationTemplates() {
      this.isLoading = true;

      try {
        const evaluations = ["course_evaluation1", "course_evaluation2"];

        for (const evaluation of evaluations) {
          if (!this.group[evaluation]) continue;

          const groupEvaluation = this.groupEvaluations.find(
            (el) => el.id === this.group[evaluation],
          );

          let groupPluginId = "";

          if (!groupEvaluation || !groupEvaluation.id) {
            const evaluationDuplicate = {
              specs: [
                this.group.id,
                {
                  group_plugin_id: this.group[evaluation],
                },
              ],
            };

            evaluationDuplicate.response = await this.$api.groupUserAttributes.duplicate(
              ...evaluationDuplicate.specs,
            );

            groupPluginId =
              evaluationDuplicate.response.data.data.group_plugin_id;

            const groupUpdate = {
              specs: [
                this.group.id,
                {
                  [evaluation]: groupPluginId,
                },
              ],
            };

            await this.$api.groups.update(...groupUpdate.specs);

            await this.listGroupEvaluations();

            this.group[evaluation] = groupPluginId;
          } else {
            groupPluginId = groupEvaluation.id;
          }

          const pluginUpdate = {
            specs: [
              this.group.id,
              groupPluginId,
              {
                signup_active_from: moment
                  .utc(this.group.course_start_date)
                  .format(this.dateFormat),
                signup_active_till: moment
                  .utc(this.group.course_end_date)
                  .format(this.dateFormat),
              },
            ],
          };

          await this.$api.groupPlugins.update(...pluginUpdate.specs);
        }

        this.isLoading = false;
        this.stepper += 1;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async duplicateRegistrationTemplate() {
      const isValid = await this.$validator.validateAll("registration");

      if (!isValid) return;

      this.isLoading = true;

      try {
        const groupRegistration = this.groupRegistrations.find(
          (el) => el.id === parseInt(this.group.course_registration, 10),
        );

        let groupPluginId = "";

        if (!groupRegistration) {
          const registrationDuplicate = {
            specs: [
              this.group.id,
              {
                group_plugin_id: this.group.course_registration,
              },
            ],
          };

          registrationDuplicate.response = await this.$api.groupUserAttributes.duplicate(
            ...registrationDuplicate.specs,
          );

          groupPluginId =
            registrationDuplicate.response.data.data.group_plugin_id;

          const groupUpdate = {
            specs: [
              this.group.id,
              {
                course_registration: groupPluginId,
              },
            ],
          };

          await this.$api.groups.update(...groupUpdate.specs);

          await this.listGroupRegistrations();

          this.group.course_registration = groupPluginId;
        } else {
          groupPluginId = groupRegistration.id;
        }

        const pluginUpdate = {
          specs: [
            this.group.id,
            groupPluginId,
            {
              signup_active_from: moment
                .utc(this.group.course_start_date)
                .format(this.dateFormat),
              signup_active_till: moment
                .utc(this.group.course_end_date)
                .format(this.dateFormat),
            },
          ],
        };

        await this.$api.groupPlugins.update(...pluginUpdate.specs);

        this.isLoading = false;
        this.stepper += 1;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async createGroupReport(groupId) {
      if (!groupId) return;

      try {
        const specs = [
          groupId,
          {
            name: "Course wizard report",
            group_plugin_id: this.groupPlugin.id,
          },
        ];

        const response = await this.$api.groupReports.create(...specs);

        if (!response) return;

        this.groupReport = response.data.data;
      } catch (error) {}
    },
    async getGroupReport() {
      if (!this.groupId) return;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPlugin.id,
        },
      ];

      const response = await this.$api.groupReports.list(...params);

      this.groupReport = response.data.data[0];
    },
    async createGroup() {
      const isValid = await this.$validator.validateAll("overall-settings");

      if (!isValid) return;

      this.isLoading = true;

      try {
        const groupsCreateSpecs = {
          name: this.group.name,
          course_signature: this.group.course_signature,
          group_min_subscribtions: this.group.group_min_subscribtions,
          group_max_subscribtions: this.group.group_max_subscribtions,
          group_waiting_list: this.group.group_waiting_list,
          course_confirmation_date: moment
            .utc(this.group.course_confirmation_date)
            .format(this.dateFormat),
          course_subscription_end_date: moment
            .utc(this.group.course_subscription_end_date)
            .format(this.dateFormat),
          course_start_date: moment
            .utc(this.group.course_start_date)
            .format(this.dateFormat),
          course_end_date: moment
            .utc(this.group.course_end_date)
            .format(this.dateFormat),
          tags: this.group.tags,
          printout_template_name: this.group.printout_template_name
            ? this.group.printout_template_name.id
            : null,
          group_venue_id: this.group.group_venue
            ? this.group.group_venue.id
            : null,
          public: this.group.public,
          protected: 0,
          with_attributes: 1,
        };

        const groupsCreateResponse = await this.$api.groups.create(
          groupsCreateSpecs,
        );

        if (!groupsCreateResponse.data || !groupsCreateResponse.data.data) {
          return;
        }

        const groupData = groupsCreateResponse.data.data;

        this.group = await this.transformGroupForEdit(groupData);

        this.$router.push(
          {
            name: "courses_edit",
            params: { group_id: groupData.id },
          },
          () => {},
        );

        await this.createGroupFeatures(groupData.id);
        await this.createGroupPlugin(groupData.id);
        await this.createGroupReport(groupData.id);

        this.isLoading = false;
        this.stepper += 1;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async transformGroupForEdit(model) {
      const attributes = model.attributes;

      let transformedAttributes = {};

      if (attributes) {
        transformedAttributes = {
          course_signature: attributes.course_signature,
          course_confirmation_date: attributes.course_confirmation_date,
          course_subscription_end_date: attributes.course_subscription_end_date,
          course_start_date: attributes.course_start_date,
          course_end_date: attributes.course_end_date,
          email_sender_address: attributes.email_sender_address,
          email_sender_name: attributes.email_sender_name,
          sms_sender_name: attributes.sms_sender_name,
          course_evaluation1: attributes.course_evaluation1,
          course_evaluation2: attributes.course_evaluation2,
          course_registration: attributes.course_registration,
          course_description: attributes.course_description,
          course_files: [],
          course_notes: attributes.course_notes,
          course_notes_email: attributes.course_notes_email,
          is_complete: attributes.is_complete,
        };
      }

      if (attributes.course_files) {
        const params = { ids: attributes.course_files };

        const response = await this.$api.media.list(params).catch(() => {});

        transformedAttributes.course_files = response.data.data;
      }

      return {
        id: model.id,
        name: model.name,
        group_max_subscribtions: model.group_max_subscribtions,
        group_min_subscribtions: model.group_min_subscribtions,
        group_waiting_list: model.group_waiting_list,
        printout_template_name: model.printout_template_name
          ? this.printoutTemplates.find(
              (el) => el.id === model.printout_template_name,
            )
          : null,
        tags: model.tags,
        group_venue: model.group_venue,
        is_template: model.is_template,
        wallet_user_id: model.wallet_user_id,
        published: model.published,
        public: model.public,
        ...transformedAttributes,
      };
    },
    async getGroup() {
      if (!this.groupId || this.group.id) {
        return;
      }

      try {
        this.isLoading = true;

        const params = [this.groupId, { with_attributes: 1 }];

        const response = await this.$api.groups.get(...params);

        this.isLoading = false;

        if (!response.data || !response.data.data) {
          return;
        }

        this.group = await this.transformGroupForEdit(response.data.data);
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async updateGroup() {
      const isValid = await this.$validator.validateAll("overall-settings");

      if (!isValid) return;

      this.isLoading = true;

      try {
        const groupsUpdateSpecs = [
          this.group.id,
          {
            name: this.group.name,
            course_signature: this.group.course_signature,
            group_min_subscribtions: this.group.group_min_subscribtions,
            group_max_subscribtions: this.group.group_max_subscribtions,
            group_waiting_list: this.group.group_waiting_list,
            course_start_date: moment
              .utc(this.group.course_start_date)
              .format(this.dateFormat),
            course_end_date: moment
              .utc(this.group.course_end_date)
              .format(this.dateFormat),
            course_confirmation_date: moment
              .utc(this.group.course_confirmation_date)
              .format(this.dateFormat),
            course_subscription_end_date: moment
              .utc(this.group.course_subscription_end_date)
              .format(this.dateFormat),
            tags: this.group.tags,
            printout_template_name: this.group.printout_template_name
              ? this.group.printout_template_name.id
              : null,
            group_venue_id: this.group.group_venue
              ? this.group.group_venue.id
              : null,
            public: this.group.public,
            with_attributes: 1,
          },
        ];

        const groupsUpdateResponse = await this.$api.groups.update(
          ...groupsUpdateSpecs,
        );

        if (!groupsUpdateResponse.data || !groupsUpdateResponse.data.data) {
          return;
        }

        const groupData = groupsUpdateResponse.data.data;

        this.group = await this.transformGroupForEdit(groupData);

        this.isLoading = false;
        this.stepper += 1;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async transformAudienceFilters(filters) {
      if (!filters) return [];

      const result = [];

      for (const filter of filters) {
        if (filter.name && filter.name === "group_subscriber_type") {
          result.push({
            property: filter.property,
            operator: filter.operator,
            value: this.group.id,
          });
          continue;
        }

        if (filter.name && filter.name === "group_roles") {
          result.push({
            property: `group_${this.group.id}_roles`,
            operator: filter.operator,
            value: filter.value,
          });
          continue;
        }

        if (filter.name && filter.name === "reserved_progress") {
          if (!this.group[filter.attribute_id]) continue;

          const categoryList = {
            params: [
              this.group.id,
              {
                group_plugin_id: this.group[filter.attribute_id],
                per_page: 1,
                page: 1,
              },
            ],
          };

          categoryList.response = await this.$api.groupUserAttributeCategories.list(
            ...categoryList.params,
          );

          const categoryId = categoryList.response.data.data[0].id;

          const reservedList = {
            params: [
              this.group.id,
              {
                categories: categoryId,
                reserved_only: 1,
              },
            ],
          };

          reservedList.response = await this.$api.groupUserAttributes.list(
            ...reservedList.params,
          );

          const attribute = reservedList.response.data.data.find(
            (el) => el.slug === `reserved_g${categoryId}_progress`,
          );

          const transfomedFilter = {
            attribute_id: attribute.id,
            operator: filter.operator,
          };

          if (filter.value) {
            transfomedFilter.value = filter.value;
          }

          result.push(transfomedFilter);
          continue;
        }

        result.push(filter);
      }

      return result;
    },
    async createGroupAudiences() {
      for (const audience of audiences) {
        const pluginStorageAudience = this.groupPlugin.audiences.find(
          (el) => el.slug === audience.slug,
        );

        const localStorageAudience = this.localStorageAudiences.find(
          (el) => el.slug === audience.slug,
        );

        if (pluginStorageAudience || localStorageAudience) continue;

        const filters = await this.transformAudienceFilters(audience.filters);

        if (!filters.length) continue;

        const audiencesCreate = {
          specs: [
            this.group.id,
            {
              name: audience.name,
              description: audience.description,
              filters: filters,
              group_plugin_id: this.groupPlugin.id,
            },
          ],
        };

        audiencesCreate.response = await this.$api.groupAudiences.create(
          ...audiencesCreate.specs,
        );

        this.$store.dispatch("saveGroupAudienceDataInStorage", {
          group_id: this.group.id,
          id: audiencesCreate.response.data.data.id,
          slug: audience.slug,
        });
      }

      const storageAudiences = [
        ...this.groupPlugin.audiences,
        ...this.localStorageAudiences,
      ];

      const pluginsUpdate = {
        params: [
          this.group.id,
          this.groupPlugin.id,
          {
            audience_data_storage: JSON.stringify(storageAudiences),
            with_attributes: 1,
          },
        ],
      };

      pluginsUpdate.response = await this.$api.groupPlugins.update(
        ...pluginsUpdate.params,
      );

      this.$store.dispatch("removeGroupAudiencesFromStorage", this.group.id);

      this.groupPlugin = this.transformGroupPluginForEdit(
        pluginsUpdate.response.data.data,
      );
    },
    async createGroupMessageTemplates() {
      for (const messageTemplate of messageTemplates) {
        if (messageTemplate.conditions.length) {
          const valid = messageTemplate.conditions.some(
            (c) => this.group[c.attribute] === c.value,
          );

          if (!valid) continue;
        }

        const messageTemplateAudiences = [];

        for (const audience of messageTemplate.audiences) {
          const pluginStorageAudience = this.groupPlugin.audiences.find(
            (el) => el.slug === audience,
          );

          if (!pluginStorageAudience) continue;

          messageTemplateAudiences.push(pluginStorageAudience.id);
        }

        const pluginStorageMessageTemplate = this.groupPlugin.messageTemplates.find(
          (el) => el.slug === messageTemplate.slug,
        );

        const localStorageMessageTemplate = this.localStorageMessageTemplates.find(
          (el) => el.slug === messageTemplate.slug,
        );

        const specs = {
          name: messageTemplate.name,
          subject: this.setPlaceholders(messageTemplate.subject),
          body: this.setPlaceholders(messageTemplate.body),
          audiences: messageTemplateAudiences.toString(),
          send_time_start: moment.utc().format("YYYY-MM-DDTHH:mm:ssZ"),
          type: "email",
          group_plugin_id: this.groupPlugin.id,
          active: messageTemplate.active,
        };

        if (pluginStorageMessageTemplate || localStorageMessageTemplate) {
          const messageTemplateId = pluginStorageMessageTemplate
            ? pluginStorageMessageTemplate.id
            : localStorageMessageTemplate.id;

          await this.$api.groupMessageTemplates.update(
            this.group.id,
            messageTemplateId,
            specs,
          );

          continue;
        }

        const messageTemplateCreateResponse = await this.$api.groupMessageTemplates.create(
          this.group.id,
          specs,
        );

        this.$store.dispatch("saveGroupMessageTemplateDataInStorage", {
          group_id: this.group.id,
          id: messageTemplateCreateResponse.data.data.id,
          slug: messageTemplate.slug,
        });
      }

      const storageMessageTemplates = [
        ...this.groupPlugin.messageTemplates,
        ...this.localStorageMessageTemplates,
      ];

      const pluginsUpdate = {
        params: [
          this.group.id,
          this.groupPlugin.id,
          {
            message_template_data_storage: JSON.stringify(
              storageMessageTemplates,
            ),
            with_attributes: 1,
          },
        ],
      };

      pluginsUpdate.response = await this.$api.groupPlugins.update(
        ...pluginsUpdate.params,
      );

      this.$store.dispatch(
        "removeGroupMessageTemplatesFromStorage",
        this.group.id,
      );

      this.groupPlugin = this.transformGroupPluginForEdit(
        pluginsUpdate.response.data.data,
      );
    },
    async createGroupTriggers() {
      for (const trigger of triggers) {
        const pluginStorageTrigger = this.groupPlugin.triggers.find(
          (el) => el.slug === trigger.slug,
        );

        const localStorageTrigger = this.localStorageTriggers.find(
          (el) => el.slug === trigger.slug,
        );

        // spec init
        const specs = {
          name: trigger.name,
          event_name: trigger.event_name,
          condition: [],
          actions: [],
          scheduled_at: null,
          enabled: trigger.enabled,
        };

        // spec conditions
        if (trigger.conditions && trigger.conditions.length) {
          for (const condition of trigger.conditions) {
            condition.id = this.group.id;

            specs.condition.push(condition);
          }
        }

        // spec actions
        if (trigger.actions && trigger.actions.length) {
          for (const action of trigger.actions) {
            if (action.key === "MessageTemplateUpdateAction") {
              const messageTemplate = this.groupPlugin.messageTemplates.find(
                (el) => el.slug === action.payload.message_template,
              );

              if (!messageTemplate) continue;

              specs.actions.push({
                name: action.name,
                key: action.key,
                payload: {
                  id: messageTemplate.id,
                  active: action.payload.active,
                },
                enabled: action.enabled,
              });
            }

            if (action.key === "GroupUpdateAction") {
              action.payload.id = this.group.id;

              specs.actions.push({
                name: action.name,
                key: action.key,
                payload: action.payload,
                enabled: action.enabled,
              });
            }
          }
        }

        // spec scheduled at
        const scheduledAt = trigger.scheduled_at;

        if (scheduledAt) {
          if (scheduledAt.attribute === "now") {
            specs.scheduled_at = moment.utc().format("YYYY-MM-DDTHH:mm:ssZ");
          } else {
            if (scheduledAt.method === "add") {
              specs.scheduled_at = moment
                .utc(this.group[scheduledAt.attribute])
                .add(scheduledAt.unit, scheduledAt.value)
                .format("YYYY-MM-DDTHH:mm:ssZ");
            }
            if (scheduledAt.method === "subtract") {
              specs.scheduled_at = moment
                .utc(this.group[scheduledAt.attribute])
                .subtract(scheduledAt.unit, scheduledAt.value)
                .format("YYYY-MM-DDTHH:mm:ssZ");
            }
            if (scheduledAt.method === "") {
              specs.scheduled_at = moment
                .utc(this.group[scheduledAt.attribute])
                .format("YYYY-MM-DDTHH:mm:ssZ");
            }
          }
        }

        if (pluginStorageTrigger || localStorageTrigger) {
          const triggerId = pluginStorageTrigger
            ? pluginStorageTrigger.id
            : localStorageTrigger.id;

          await this.$api.groupTriggers.update(this.group.id, triggerId, specs);

          continue;
        }

        const response = await this.$api.groupTriggers.create(
          this.group.id,
          specs,
        );

        this.$store.dispatch("saveGroupTriggerDataInStorage", {
          group_id: this.group.id,
          id: response.data.data.id,
          slug: trigger.slug,
        });
      }

      const storageTriggers = [
        ...this.groupPlugin.triggers,
        ...this.localStorageTriggers,
      ];

      const pluginsUpdate = {
        params: [
          this.group.id,
          this.groupPlugin.id,
          {
            trigger_data_storage: JSON.stringify(storageTriggers),
            with_attributes: 1,
          },
        ],
      };

      pluginsUpdate.response = await this.$api.groupPlugins.update(
        ...pluginsUpdate.params,
      );

      this.$store.dispatch("removeGroupTriggersFromStorage", this.group.id);

      this.groupPlugin = this.transformGroupPluginForEdit(
        pluginsUpdate.response.data.data,
      );
    },
    async updateGroupDescription() {
      const groupsUpdateSpecs = [
        this.group.id,
        {
          course_description: this.group.course_description,
          course_files: this.group.course_files.map((el) => el.id).toString(),
          course_notes: this.group.course_notes,
          course_notes_email: this.group.course_notes_email ? 1 : 0,
          published: 1,
          is_complete: 1,
        },
      ];

      await this.$api.groups.update(...groupsUpdateSpecs);
    },
    goToUserList() {
      const params = [this.group.id];

      this.$store.dispatch("getGroupLeftMenu", params).catch(() => {});

      this.$router
        .push({
          name: "group_users",
        })
        .catch(() => {});
    },
    async closeWizard() {
      this.$router.push(
        {
          name: "courses",
        },
        () => {},
      );
    },
    async changeNext() {
      if (!this.group.id) {
        this.createGroup();
        return;
      }

      if (this.activeStep === "settings") {
        this.updateGroup();
        return;
      }

      if (this.activeStep === "evaluations") {
        this.duplicateEvaluationTemplates();
        return;
      }

      if (this.activeStep === "teachers") {
        if (!this.teachers.length) {
          this.errors.add({
            msg: "Course must have teacher",
            scope: "teachers",
            field: "administrators",
          });
        } else {
          this.stepper += 1;
        }
        return;
      }

      if (this.activeStep === "coordinators") {
        if (!this.coordinators.length) {
          this.errors.add({
            msg: "Course must have coordinator",
            scope: "coordinators",
            field: "administrators",
          });
        } else {
          this.stepper += 1;
        }
        return;
      }

      if (this.activeStep === "registration") {
        this.duplicateRegistrationTemplate();
        return;
      }

      if (this.activeStep === "description") {
        try {
          this.isLoading = true;

          await this.createGroupAudiences();
          await this.createGroupMessageTemplates();
          await this.createGroupTriggers();

          await this.updateGroupDescription();

          this.isLoading = false;
          this.stepper += 1;
        } catch (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
        return;
      }
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.fetchGroupData();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper-header {
  position: relative;
  margin: 0 -24px 72px -24px;
  &:before {
    content: "";
    height: 1px;
    position: absolute;
    top: calc(50% - 1px);
    right: 24px;
    left: 24px;
    background: $accent;
    opacity: 0.5;
  }
}
.step {
  position: relative;
  z-index: 1;
  max-width: max-content;
  background: #fafafa;
}
.step-content {
  opacity: 0.5;
}
.step.active .step-content,
.step.complete .step-content {
  opacity: 1;
}
</style>
