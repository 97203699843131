<template>
  <div class="course-wizard-description">
    <v-form data-vv-scope="description">
      <v-layout row wrap>
        <v-flex xs12 class="mb-5">
          <div class="mb-4 title font-weight-black">
            {{ $t("course_wizard.course_description") }}
          </div>
          <QuillEditor
            v-model="group.course_description"
            :config="editorConfig"
          />
        </v-flex>
        <v-flex xs12>
          <v-switch
            v-model="group.course_notes_email"
            :label="$t('course_wizard.course_notes_email_label')"
          ></v-switch>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="mb-4 title font-weight-black">
            {{ $t("course_wizard.course_notes") }}
          </div>
          <QuillEditor v-model="group.course_notes" :config="editorConfig" />
        </v-flex>

        <v-flex xs4>
          <v-card class="border-radius">
            <v-card-text class="py-5 text-center">
              <div class="mb-5 title font-weight-black">
                {{ $t("course_wizard.add_files") }}
              </div>
              <MediaManager
                v-model="group.course_files"
                :media-type="'files'"
                :max-files="5"
                :no-media-selected="$t('media_manager.no_media_selected')"
                :label="''"
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
export default {
  computed: {
    group: {
      get() {
        return this.$store.getters.courseWizardGroup;
      },
      set(v) {
        this.$store.dispatch("setCourseWizardGroup", v);
      },
    },
    editorConfig() {
      return {
        label: "",
        placeholder: this.$t("course_wizard.course_description_placeholder"),
        style: {
          height: "250px",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
