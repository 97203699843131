<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <v-card class="border-radius">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text class="py-0 text-center headline font-weight-black">
        {{ title }}
      </v-card-text>
      <v-card-text v-if="window === 0">
        <v-form data-vv-scope="form-0" @submit.prevent="findUser">
          <v-layout row wrap class="px-5">
            <v-flex xs12 class="mb-4">
              <v-text-field
                v-model="email"
                data-vv-name="email"
                :data-vv-as="$tc('email', 2)"
                v-validate.disable="'required|email'"
                :placeholder="$tc('email', 1)"
                hide-details
              ></v-text-field>
              <ErrorMessages :error-messages="errors.collect('form-0.email')" />
            </v-flex>
            <v-flex xs12 class="text-xs-center">
              <v-btn
                round
                large
                class="theme-accent-bg white--text text-none"
                :loading="isLoading"
                @click="findUser"
                >{{ $t("find-user") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-text v-if="window === 1">
        <v-form data-vv-scope="form-1" @submit.prevent>
          <v-layout row wrap class="px-5">
            <v-flex xs12>
              <v-text-field
                v-model="newUser.first_name"
                v-validate.disable="'required'"
                data-vv-name="first_name"
                :data-vv-as="$t('common.first_name')"
                :placeholder="$t('common.first_name')"
                hide-details
              ></v-text-field>
              <ErrorMessages
                :error-messages="errors.collect('form-1.first_name')"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="newUser.last_name"
                v-validate.disable="'required'"
                data-vv-name="last_name"
                :data-vv-as="$t('common.last_name')"
                :placeholder="$t('common.last_name')"
                hide-details
              ></v-text-field>
              <ErrorMessages
                :error-messages="errors.collect('form-1.last_name')"
              />
            </v-flex>
            <v-flex xs12 class="mb-3">
              <v-text-field
                v-model="email"
                :placeholder="$t('common.email')"
                :disabled="true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="text-center">
              <v-btn
                round
                large
                class="theme-accent-bg white--text text-none"
                :loading="isLoading"
                @click="createUser"
                >{{ $t("common.add") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-text v-if="window === 2">
        <v-layout row wrap class="px-5">
          <v-flex xs12>
            <v-text-field
              v-model="user.first_name"
              :placeholder="$t('common.first_name')"
              :disabled="true"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="user.last_name"
              :placeholder="$t('common.first_name')"
              :disabled="true"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="mb-3">
            <v-text-field
              v-model="email"
              :placeholder="$t('common.email')"
              :disabled="true"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="text-center">
            <v-btn
              v-if="!userGroupAdministrationRoles.length"
              round
              large
              class="theme-accent-bg white--text text-none"
              :loading="isLoading"
              @click="createGroupAdministrator(user.id)"
              >{{ $t("common.add") }}</v-btn
            >
            <v-btn
              v-if="userGroupAdministrationRoles.length"
              round
              large
              class="theme-accent-bg white--text text-none"
              :loading="isLoading"
              @click="updateGroupAdministrator(user.id)"
              >{{ $t("common.add") }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    window: 0,
    email: "",
    newUser: {},
    userGroupAdministrationRoles: [],
    user: {},
  }),
  computed: {
    group() {
      return this.$store.getters.courseWizardGroup;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    async findUser() {
      const isValid = await this.$validator.validateAll("form-0");

      if (!isValid) return;

      this.isLoading = true;

      try {
        const userSearchParams = { email: this.email };

        const userSearchResponse = await this.$api.users.search(
          userSearchParams,
        );

        if (!userSearchResponse.data || !userSearchResponse.data.data[0]) {
          this.isLoading = false;
          this.window = 1;
          return;
        }

        this.user = userSearchResponse.data.data[0];

        const groupAdministratorsGetParams = [
          this.group.id,
          userSearchResponse.data.data[0].id,
        ];

        const groupAdministratorsGetResponse = await this.$api.groupAdministrators.get(
          ...groupAdministratorsGetParams,
        );

        if (
          !groupAdministratorsGetResponse.data ||
          !groupAdministratorsGetResponse.data.data.id
        ) {
          this.isLoading = false;
          return;
        }

        const groupAdministrationRoles =
          groupAdministratorsGetResponse.data.data.group_administration_roles;

        if (groupAdministrationRoles.includes(this.role)) {
          this.errors.add({
            msg: this.$t(`course_wizard.already_${this.role}_error`),
            scope: "form-0",
            field: "email",
          });
        } else {
          this.userGroupAdministrationRoles = groupAdministrationRoles;
          this.window = 2;
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;

        if (error.response && error.response.status === 404) {
          this.window = 2;
        }
      }
    },
    async createUser() {
      const isValid = await this.$validator.validateAll("form-1");

      if (!isValid) return;

      this.isLoading = true;

      const specs = {
        first_name: this.newUser.first_name,
        last_name: this.newUser.last_name,
        email: this.email,
        password: Math.random()
          .toString(36)
          .substr(2, 8),
      };

      this.$api.users.create(specs).then(
        (response) => {
          if (!response.data || !response.data.data.id) {
            this.isLoading = false;
            return;
          }

          this.createGroupAdministrator(response.data.data.id);
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async createGroupAdministrator(id) {
      const specs = [
        this.group.id,
        {
          user_id: id,
          roles: this.role,
        },
      ];

      this.$api.groupAdministrators.create(...specs).then(
        (response) => {
          this.isLoading = false;

          if (response.status !== 200) {
            return;
          }

          this.$emit("administrator-created");

          this.dialog = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    updateGroupAdministrator(id) {
      const specs = [
        this.group.id,
        id,
        {
          roles: [...this.userGroupAdministrationRoles, this.role].toString(),
        },
      ];

      this.$api.groupAdministrators.update(...specs).then(
        (response) => {
          this.isLoading = false;

          if (response.status !== 200) {
            return;
          }

          this.$emit("administrator-created");

          this.dialog = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
  },
  watch: {
    dialog() {
      this.errors.clear();
      this.window = 0;
      this.email = "";
      this.newUser = {};
      this.user = {};
    },
  },
};
</script>

<style lang="scss" scoped></style>
