<template>
  <div class="course-wizard-evaluations">
    <v-form data-vv-scope="evaluations">
      <v-layout row wrap>
        <v-flex xs12 class="mb-5">
          <div class="title font-weight-black">
            {{ $t("course_wizard.course_evaluation1") }}
          </div>
          <v-combobox
            v-model="group.course_evaluation1"
            :data-vv-name="'first_evaluation'"
            item-value="id"
            :items="filteredEvaluations(group.course_evaluation2)"
            :return-object="false"
            :placeholder="$t('course_wizard.please_select')"
            :disabled="isEvaluationCreated(group.course_evaluation1)"
          >
            <template slot="no-data">
              <div class="pa-3">
                {{ $t("course_wizard.course_evaluation_no_data") }}
              </div>
            </template>
            <template slot="selection">
              <div>{{ getEvaluationName(group.course_evaluation1) }}</div>
            </template>
            <template slot="item" slot-scope="data">
              <div class="slot-item">{{ data.item.name }}</div>
            </template>
          </v-combobox>
        </v-flex>
        <v-flex xs12>
          <div class="title font-weight-black">
            {{ $t("course_wizard.course_evaluation2") }}
          </div>
          <v-combobox
            v-model="group.course_evaluation2"
            :data-vv-name="'second_evaluation'"
            item-value="id"
            :items="filteredEvaluations(group.course_evaluation1)"
            :return-object="false"
            :placeholder="$t('course_wizard.please_select')"
            :disabled="isEvaluationCreated(group.course_evaluation2)"
          >
            <template slot="no-data">
              <div class="pa-3">
                {{ $t("course_wizard.course_evaluation_no_data") }}
              </div>
            </template>
            <template slot="selection">
              <div>{{ getEvaluationName(group.course_evaluation2) }}</div>
            </template>
            <template slot="item" slot-scope="data">
              <div class="slot-item">{{ data.item.name }}</div>
            </template>
          </v-combobox>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
export default {
  inject: ["parentValidator"],
  computed: {
    evaluationTemplates() {
      return this.$store.getters.courseWizardEvaluationTemplates;
    },
    groupEvaluations() {
      return this.$store.getters.courseWizardGroupEvaluations;
    },
    evaluations() {
      return [...this.evaluationTemplates, ...this.groupEvaluations];
    },
    group: {
      get() {
        return this.$store.getters.courseWizardGroup;
      },
      set(v) {
        this.$store.dispatch("setCourseWizardGroup", v);
      },
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    filteredEvaluations(id) {
      if (!id) {
        return this.evaluations;
      }

      return this.evaluations.filter((el) => el.id != id);
    },
    getEvaluationName(id) {
      if (!id) return;

      const evaluation = this.evaluations.find((el) => el.id === id);

      return evaluation ? evaluation.name : "";
    },
    isEvaluationCreated(id) {
      if (!id) return;

      return this.groupEvaluations.find((el) => el.id === id) ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
