<template>
  <div class="course-wizard-registration">
    <v-form data-vv-scope="registration">
      <div class="title font-weight-black">
        {{ $t("course_wizard.course_registration") }}
      </div>
      <v-combobox
        v-model="group.course_registration"
        v-validate.disable="'required'"
        :data-vv-name="'course_registration'"
        :data-vv-as="$t('course_wizard.course_registration_placeholder')"
        item-value="id"
        :items="registrations"
        :return-object="false"
        :placeholder="$t('course_wizard.please_select')"
        :disabled="isRegistrationCreated(group.course_registration)"
        hide-details
      >
        <template slot="no-data">
          <div class="pa-3">
            {{ $t("course_wizard.course_registration_no_data") }}
          </div>
        </template>
        <template slot="selection">
          <div>{{ getRegistrationName(group.course_registration) }}</div>
        </template>
        <template slot="item" slot-scope="data">
          <div class="slot-item">{{ data.item.name }}</div>
        </template>
      </v-combobox>
      <ErrorMessages
        :error-messages="errors.collect('registration.course_registration')"
      />
    </v-form>
  </div>
</template>

<script>
export default {
  inject: ["parentValidator"],
  computed: {
    registrationTemplates() {
      return this.$store.getters.courseWizardRegistrationTemplates;
    },
    groupRegistrations() {
      return this.$store.getters.courseWizardGroupRegistrations;
    },
    registrations() {
      return [...this.registrationTemplates, ...this.groupRegistrations];
    },
    group: {
      get() {
        return this.$store.getters.courseWizardGroup;
      },
      set(v) {
        this.$store.dispatch("setCourseWizardGroup", v);
      },
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    getRegistrationName(id) {
      if (!id) return;

      const registration = this.registrations.find((el) => el.id === id);

      return registration ? registration.name : "";
    },
    isRegistrationCreated(id) {
      if (!id) return;

      return this.groupRegistrations.find((el) => el.id === id) ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
