<template>
  <div class="course-wizard-overview">
    <v-layout row wrap>
      <v-flex xs12 class="display-1 text-center">
        {{ $t("course_wizard.completed") }}
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
