<template>
  <div class="group-wizard-administrators">
    <v-layout row wrap class="pb-3">
      <v-flex xs12 class="mb-5">
        <v-layout row wrap>
          <v-flex xs5 class="mb-5">
            <v-card>
              <v-card-text class="text-center">
                <div class="mb-3 title font-weight-black">
                  {{ $t("course_wizard.add_coordinators") }}
                </div>
                <v-btn fab class="theme-accent-bg" @click="addAdministrator">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <div class="mb-4 title font-weight-black">
          {{ $t("course_wizard.added_coordinators") }}
        </div>
        <div
          v-if="!addedAdministrators || !addedAdministrators.length"
          class="caption"
        >
          {{ $t("course_wizard.added_coordinators_no_data") }}
        </div>
        <ErrorMessages
          :error-messages="errors.collect('coordinators.administrators')"
        />
        <v-layout
          v-if="addedAdministrators && addedAdministrators.length"
          row
          wrap
          class="mx-n1"
        >
          <v-flex
            v-for="(administrator, i) in addedAdministrators"
            :key="`administrator-${i}`"
            xs3
            class="px-1"
          >
            <v-card class="border-radius fill-height">
              <v-btn
                v-if="group.wallet_user_id !== administrator.id"
                fab
                class="ma-0 theme-accent-bg white--text small-fab"
                @click="removeGroupAdministrator(administrator)"
              >
                <v-icon small>close</v-icon>
              </v-btn>
              <v-card-text>
                <div class="caption grey--text">{{ $t("common.name") }}</div>
                <div class="mb-2">
                  {{ administrator.first_name }} {{ administrator.last_name }}
                </div>
                <div class="caption grey--text">{{ $t("common.email") }}</div>
                <div>{{ administrator.email }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <AdministratorDialog
      ref="administratorDialog"
      :role="role"
      :title="$t('course_wizard.add_coordinators')"
      @administrator-created="$emit('administrator-created')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </div>
</template>

<script>
import AdministratorDialog from "@/components/CourseWizard/AdministratorDialog.vue";

export default {
  inject: ["parentValidator"],
  data: () => ({
    role: "coordinator",
  }),
  computed: {
    group() {
      return this.$store.getters.courseWizardGroup;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    administrators() {
      return this.$store.getters.courseWizardAdministrators;
    },
    addedAdministrators() {
      return this.administrators.filter(
        (el) =>
          el.id !== this.currentUser.id &&
          el.group_administration_roles.includes(this.role),
      );
    },
  },
  components: {
    AdministratorDialog,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    addAdministrator() {
      this.$refs.administratorDialog.openDialog();
    },
    async removeGroupAdministrator(administrator) {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t(`course_wizard.delete_${this.role}_warning`),
        this.$t("common.no"),
        this.$t("common.yes"),
      );

      if (!isConfirmed) return;

      const groupAdministrationRoles = administrator.group_administration_roles.filter(
        (el) => el !== this.role,
      );

      if (!groupAdministrationRoles.length) {
        const params = [this.group.id, administrator.id];

        this.$api.groupAdministrators.delete(...params).then(
          (response) => {
            if (response.status !== 200) {
              return;
            }

            this.$emit("administrator-deleted");
          },
          () => {},
        );
      } else {
        const specs = [
          this.group.id,
          administrator.id,
          {
            roles: groupAdministrationRoles.toString(),
          },
        ];

        this.$api.groupAdministrators.update(...specs).then(
          (response) => {
            this.isLoading = false;

            if (response.status !== 200) {
              return;
            }

            this.$emit("administrator-created");

            this.dialog = false;
          },
          () => {
            this.isLoading = false;
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.small-fab {
  position: absolute;
  height: 24px;
  width: 24px;
  top: -12px;
  right: -12px;
  z-index: 1;

  .v-icon {
    font-size: 12px !important;
  }
}
</style>
