<template>
  <div class="course-wizard-overall-settings">
    <v-form data-vv-scope="overall-settings">
      <v-layout row wrap>
        <v-flex xs12 class="mb-5">
          <v-switch
            v-model="group.public"
            :true-value="1"
            :false-value="0"
            :label="groupPublicLabel"
          ></v-switch>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="title font-weight-black">
            {{ $t("course_wizard.name") }}
          </div>
          <v-text-field
            v-model="group.name"
            v-validate.disable="'required'"
            :data-vv-name="'name'"
            :data-vv-as="$t('course_wizard.name')"
            :placeholder="$t('course_wizard.name_placeholder')"
            hide-details
          ></v-text-field>
          <ErrorMessages
            :error-messages="errors.collect('overall-settings.name')"
          />
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="mb-4 title font-weight-black">
            {{ $t("course_wizard.course_signature") }}
          </div>
          <QuillEditor
            v-model="group.course_signature"
            :config="editorConfig"
            class="mb-3"
          />
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="title font-weight-black">
            {{ $t("course_wizard.group_min_subscribtions") }}
          </div>
          <v-text-field
            v-model="group.group_min_subscribtions"
            v-validate.disable="'required|numeric'"
            :data-vv-name="'group_min_subscribtions'"
            :data-vv-as="$t('course_wizard.group_min_subscribtions')"
            :placeholder="$t('course_wizard.insert_amount_here')"
            hide-details
          ></v-text-field>
          <ErrorMessages
            :error-messages="
              errors.collect('overall-settings.group_min_subscribtions')
            "
          />
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="title font-weight-black">
            {{ $t("course_wizard.group_max_subscribtions") }}
          </div>
          <v-text-field
            v-model="group.group_max_subscribtions"
            v-validate.disable="
              `required|numeric|min_value:${group.group_min_subscribtions}`
            "
            :data-vv-name="'group_max_subscribtions'"
            :data-vv-as="$t('course_wizard.group_max_subscribtions')"
            :placeholder="$t('course_wizard.insert_amount_here')"
            hide-details
          ></v-text-field>
          <ErrorMessages
            :error-messages="
              errors.collect('overall-settings.group_max_subscribtions')
            "
          />
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="title font-weight-black">
            {{ $t("course_wizard.group_waiting_list") }}
          </div>
          <v-text-field
            v-model="group.group_waiting_list"
            v-validate.disable="'required|numeric'"
            :data-vv-name="'group_waiting_list'"
            :data-vv-as="$t('course_wizard.group_waiting_list')"
            :placeholder="$t('course_wizard.insert_amount_here')"
            hide-details
          ></v-text-field>
          <ErrorMessages
            :error-messages="
              errors.collect('overall-settings.group_waiting_list')
            "
          />
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="mb-4 title font-weight-black">
            {{ $t("course_wizard.dates") }}
          </div>
          <v-layout row wrap>
            <v-flex xs6 class="mb-2 pr-3">
              <div class="mb-1">
                {{ $t("course_wizard.course_confirmation_date") }}
              </div>
              <date-picker
                v-model="group.course_confirmation_date"
                type="datetime"
                width="100%"
                :lang="datePickerLang"
                :first-day-of-week="1"
                :format="'MMMM DD, YYYY H:mm'"
                :minute-step="5"
                :not-after="group.course_subscription_end_date"
                v-validate.disable="'required'"
                :name="'course_confirmation_date'"
                :data-vv-as="$t('course_wizard.course_confirmation_date')"
                :placeholder="$t('course_wizard.choose_date')"
                :editable="false"
                append-to-body
              ></date-picker>
              <ErrorMessages
                :error-messages="
                  errors.collect('overall-settings.course_confirmation_date')
                "
              />
            </v-flex>
            <v-flex xs6 class="mb-2 pl-3">
              <div class="mb-1">
                {{ $t("course_wizard.course_subscription_end_date") }}
              </div>
              <date-picker
                v-model="group.course_subscription_end_date"
                type="datetime"
                width="100%"
                :lang="datePickerLang"
                :first-day-of-week="1"
                :format="'MMMM DD, YYYY H:mm'"
                :minute-step="5"
                :not-before="group.course_confirmation_date"
                :not-after="group.course_start_date"
                v-validate.disable="'required'"
                :name="'course_subscription_end_date'"
                :data-vv-as="$t('course_wizard.course_subscription_end_date')"
                :placeholder="$t('course_wizard.choose_date')"
                :editable="false"
                append-to-body
              ></date-picker>
              <ErrorMessages
                :error-messages="
                  errors.collect(
                    'overall-settings.course_subscription_end_date'
                  )
                "
              />
            </v-flex>
            <v-flex xs6 class="mb-2 pr-3">
              <div class="mb-1">
                {{ $t("course_wizard.course_start_date") }}
              </div>
              <date-picker
                v-model="group.course_start_date"
                type="datetime"
                width="100%"
                :lang="datePickerLang"
                :first-day-of-week="1"
                :format="'MMMM DD, YYYY H:mm'"
                :minute-step="5"
                :not-before="group.course_subscription_end_date"
                :not-after="group.course_end_date"
                v-validate.disable="'required'"
                :name="'course_start_date'"
                :data-vv-as="$t('course_wizard.course_start_date')"
                :placeholder="$t('course_wizard.choose_date')"
                :editable="false"
                append-to-body
              ></date-picker>
              <ErrorMessages
                :error-messages="
                  errors.collect('overall-settings.course_start_date')
                "
              />
            </v-flex>
            <v-flex xs6 class="mb-2 pl-3">
              <div class="mb-1">{{ $t("course_wizard.course_end_date") }}</div>
              <date-picker
                v-model="group.course_end_date"
                type="datetime"
                width="100%"
                :lang="datePickerLang"
                :first-day-of-week="1"
                :format="'MMMM DD, YYYY H:mm'"
                :minute-step="5"
                :not-before="group.course_start_date"
                v-validate.disable="'required'"
                :name="'course_end_date'"
                :data-vv-as="$t('course_wizard.course_end_date')"
                :placeholder="$t('course_wizard.choose_date')"
                :editable="false"
                append-to-body
              ></date-picker>
              <ErrorMessages
                :error-messages="
                  errors.collect('overall-settings.course_end_date')
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="title font-weight-black">
            {{ $t("course_wizard.printout_template") }}
          </div>
          <v-combobox
            v-model="group.printout_template_name"
            item-value="id"
            :items="printoutTemplates"
            :placeholder="$t('course_wizard.please_select')"
          >
            <template slot="no-data">
              <div class="pa-3">
                {{ $t("course_wizard.printout_template_no_data") }}
              </div>
            </template>
            <template slot="selection" slot-scope="data">
              <div>{{ data.item.name }}</div>
            </template>
            <template slot="item" slot-scope="data">
              <div class="slot-item">{{ data.item.name }}</div>
            </template>
          </v-combobox>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="title font-weight-black">
            {{ $t("course_wizard.tags") }}
          </div>
          <v-combobox
            v-model="group.tags"
            :data-vv-name="'tags'"
            :items="tags"
            :placeholder="$t('course_wizard.please_select')"
            chips
            multiple
          >
            <template slot="no-data">
              <div class="pa-3">{{ $t("course_wizard.tags_no_data") }}</div>
            </template>
            <template slot="selection" slot-scope="data">
              <v-chip close @input="remove(data.item)">{{ data.item }}</v-chip>
            </template>
            <template slot="item" slot-scope="data">
              <div class="slot-item">{{ data.item }}</div>
            </template>
          </v-combobox>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <div class="title font-weight-black">
            {{ $t("course_wizard.group_venue") }}
          </div>
          <v-combobox
            v-model="group.group_venue"
            v-validate.disable="'required'"
            :data-vv-name="'group_venue'"
            :data-vv-as="$t('course_wizard.group_venue')"
            item-text="name"
            item-value="id"
            :items="venues"
            :placeholder="$t('course_wizard.please_select')"
            hide-details
          >
            <template slot="no-data">
              <div class="pa-3">
                {{ $t("course_wizard.group_venue_no_data") }}
              </div>
            </template>
            <template slot="selection" slot-scope="data">
              <div>{{ data.item.name }}</div>
            </template>
            <template slot="item" slot-scope="data">
              <v-layout row wrap class="slot-item align-center">
                <v-flex xs6>{{ data.item.name }}</v-flex>
                <v-flex xs6 class="text-right caption">{{
                  data.item.address
                }}</v-flex>
              </v-layout>
            </template>
          </v-combobox>
          <ErrorMessages
            :error-messages="errors.collect('overall-settings.group_venue')"
          />
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  inject: ["parentValidator"],
  computed: {
    group: {
      get() {
        return this.$store.getters.courseWizardGroup;
      },
      set(v) {
        this.$store.dispatch("setCourseWizardGroup", v);
      },
    },
    printoutTemplates() {
      return this.$store.getters.courseWizardPrintoutTemplates;
    },
    tags() {
      return this.$store.getters.courseWizardTags;
    },
    venues() {
      return this.$store.getters.courseWizardVenues;
    },
    evaluationTemplates() {
      return this.$store.getters.courseWizardEvaluationTemplates;
    },
    groupPublicLabel() {
      const state = this.group.public
        ? this.$t("course_wizard.public_open")
        : this.$t("course_wizard.public_closed");

      return `${this.$t("course_wizard.public")} ${state}`;
    },
    editorConfig() {
      return {
        label: "",
        style: {
          height: "250px",
        },
      };
    },
  },
  components: {
    DatePicker,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    remove(item) {
      this.group.tags.splice(this.group.tags.indexOf(item), 1);
      this.group.tags = [...this.group.tags];
    },
  },
  watch: {
    "group.tags"(val) {
      if (val && val.length) {
        const isIncluded = this.tags.includes(val[val.length - 1]);
        if (!isIncluded) {
          this.tags.push(val[val.length - 1]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
